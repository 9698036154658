require('./bootstrap');

require('alpinejs');
//require('./plugins/jquery-zoom/jquery.zoom.min');
const masonry = require('masonry-layout');

const bootstrap = require('bootstrap');
// require('@midzer/tobii');

require('./plugins.init');

/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Loader               *
 *     02.  Toggle Menus         *
 *     03.  Active Menu          *
 *     04.  Clickable Menu       *
 *     05.  Back to top          *
 *     06.  Feather icon         *
 *     06.  DD Menu              *
 *     06.  Active Sidebar Menu  *
 *     07.  Contact us           *
 ================================*/

window.addEventListener('load',   fn , false );

//  window.onload = function loader() {
function fn() {
    // Preloader
    if(document.getElementById('preloader')){
        setTimeout(() => {
            document.getElementById('preloader').style.visibility = 'hidden';
            document.getElementById('preloader').style.opacity = '0';
        }, 350);
    }
    // Menus
    activateMenu();
    //toggleMenu();
}

document.getElementById('isToggle').addEventListener( 'click', function(){
    document.getElementById('isToggle').classList.toggle('open');
    const isOpen = document.getElementById('navigation');
    if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
    } else {
        isOpen.style.display = "block";
    }
});

// Popover
var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
})

//Menu
// Toggle menu
// function toggleMenu() {
//     document.getElementById('isToggle').classList.toggle('open');
//     const isOpen = document.getElementById('navigation');
//     if (isOpen.style.display === "block") {
//         isOpen.style.display = "none";
//     } else {
//         isOpen.style.display = "block";
//     }
// }

//Menu Active
function getClosest(elem, selector) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) { }
                return i > -1;
            };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;

}

function activateMenu() {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {

        var matchingMenuItem = null;
        for (var idx = 0; idx < menuItems.length; idx++) {
            if (menuItems[idx].href === window.location.href) {
                matchingMenuItem = menuItems[idx];
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add('active');
            var immediateParent = getClosest(matchingMenuItem, 'li');
            if (immediateParent) {
                immediateParent.classList.add('active');
            }

            var parent = getClosest(matchingMenuItem, '.parent-menu-item');
            if (parent) {
                parent.classList.add('active');
                var parentMenuitem = parent.querySelector('.menu-item');
                if (parentMenuitem) {
                    parentMenuitem.classList.add('active');
                }
                var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                if (parentOfParent) {
                    parentOfParent.classList.add('active');
                }
            } else {
                var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                if (parentOfParent) {
                    parentOfParent.classList.add('active');
                }
            }
        }
    }
}

let i;
let len;
// Clickable Menu
if(document.getElementById("navigation")){
    const elements = document.getElementById("navigation").getElementsByTagName("a");
    for(; i < len; i++) {
        elements[i].onclick = function (elem) {
            if(elem.target.getAttribute("href") === "javascript:void(0)") {
                const submenu = elem.target.nextElementSibling.nextElementSibling;
                submenu.classList.toggle('open');
            }
        }
    }
}

// Menu sticky
function windowScroll() {
    const navbar = document.getElementById("topnav");
    if(navbar!=null){
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add("nav-sticky");
        } else {
            navbar.classList.remove("nav-sticky");
        }
    }
}

window.addEventListener('scroll', (ev) => {
    ev.preventDefault();
    windowScroll();
});

document.getElementById('back-to-top').addEventListener( 'click', function (ev){
    ev.preventDefault();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});
// back-to-top
const backToTop = document.getElementById("back-to-top");
window.onscroll = function () {
    scrollFunction();
};

function scrollFunction() {
    if(backToTop!=null){
        if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            backToTop.style.display = "block";
        } else {
            backToTop.style.display = "none";
        }
    }
}

// function topFunction() {
//     document.body.scrollTop = 0;
//     document.documentElement.scrollTop = 0;
// }

//ACtive Sidebar
// (function () {
//     const current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
//     if (current === "") return;
//     const menuItems = document.querySelectorAll('.sidebar-nav a');
//     let i = 0, len = menuItems.length;
//     for (; i < len; i++) {
//         if (menuItems[i].getAttribute("href").indexOf(current) !== -1) {
//             menuItems[i].parentElement.className += " active";
//         }
//     }
// })();

//Feather icon
//feather.replace();

// dd-menu
const ddmenu = document.getElementsByClassName("dd-menu");
i = 0;
len = ddmenu.length;
for(; i < len; i++) {
    ddmenu[i].onclick = function (elem) {
        elem.stopPropagation();
    }
}

//Tooltip
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
});

//small menu
try {
    var spy = new Gumshoe('#navmenu-nav a');
}catch(err) {

}

//=========================================//
/*/*            08) Tobii lightbox         */
//=========================================//

// try {
//     const tobii = new Tobii()
// } catch (err) {
//     //console.log(err);
// }

// document.addEventListener('DOMContentLoaded', () => {
//     // This assumes your article is wrapped in an element with the class "content-article".
//     document.querySelectorAll('.lightbox img').forEach((articleImg) => {
//         // Add lightbox elements in blog articles for Tobii.
//         const lightbox = document.createElement('a');
//         lightbox.href = articleImg.src;
//         lightbox.classList.add('lightbox');
//         lightbox.dataset.group = 'article';
//         articleImg.parentNode.appendChild(lightbox);
//         lightbox.appendChild(articleImg);
//     });
// });

//********************* */
/*     Contact us       */
//********************* */
try {
    function validateForm() {
        var name = document.forms["myForm"]["name"].value;
        var email = document.forms["myForm"]["email"].value;
        var subject = document.forms["myForm"]["subject"].value;
        var comments = document.forms["myForm"]["comments"].value;
        document.getElementById("error-msg").style.opacity = 0;
        document.getElementById('error-msg').innerHTML = "";
        if (name == "" || name == null) {
            document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please enter a Name*</div>";
            fadeIn();
            return false;
        }
        if (email == "" || email == null) {
            document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please enter a Email*</div>";
            fadeIn();
            return false;
        }
        if (subject == "" || subject == null) {
            document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please enter a Subject*</div>";
            fadeIn();
            return false;
        }
        if (comments == "" || comments == null) {
            document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please enter a Comments*</div>";
            fadeIn();
            return false;
        }
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                document.getElementById("simple-msg").innerHTML = this.responseText;
                document.forms["myForm"]["name"].value = "";
                document.forms["myForm"]["email"].value = "";
                document.forms["myForm"]["subject"].value = "";
                document.forms["myForm"]["comments"].value = "";
            }
        };
        xhttp.open("POST", "php/contact.php", true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send("name=" + name + "&email=" + email + "&subject=" + subject + "&comments=" + comments);
        return false;
    }

    function fadeIn() {
        var fade = document.getElementById("error-msg");
        var opacity = 0;
        var intervalID = setInterval(function () {
            if (opacity < 1) {
                opacity = opacity + 0.5
                fade.style.opacity = opacity;
            } else {
                clearInterval(intervalID);
            }
        }, 200);
    }
} catch (err) {

}


window.addEventListener('DOMContentLoaded', function(){
    setTimeout(function(){
        if(document.querySelector('.modal.has-error, .modal.open-on-load') != undefined){
            let modal = new bootstrap.Modal(document.querySelector('.modal.has-error, .modal.open-on-load'), {
                keyboard: false
            });
            modal.show();
        }
    }, 500);
});

window.addEventListener("load", event => {
    const images = document.querySelectorAll('.masonry.row img');
    let isLoaded = false;
    images.forEach(function(value, index){
        isLoaded = value.complete && value.naturalHeight !== 0;
    })

    if(isLoaded){
        // Initiate Masonry layout
        new masonry( document.querySelector('.masonry.row'), {
            // options
            percentPosition: true
        });
    }
});

// window.addEventListener('DOMContentLoaded', function(){
//     setTimeout(function(){
//         if(document.querySelector('#showAuthLoginModal.modal:not(.has-error)') != undefined){
//             let modal = new bootstrap.Modal(document.querySelector('.modal#showAuthLoginModal:not(.has-error)'), {
//                 keyboard: false
//             });
//             modal.show();
//         }
//     }, 600);
// });
